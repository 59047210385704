import { Button, Card, Form, Input, message, Select, Switch } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import ImageInput from '../../components/ImageInput/imageInput';
import { apiFormsGet, getOwnerOfTheForm } from '../../services/api/forms';
import { ApiFormsGetResponse } from '../../services/api/types/forms';
import { selectUserState } from '../../store/selectors/user';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { apiStepsCreate } from '../../services/api/steps';
import { RouterPaths } from '../../router/paths';
import { ALLOWED_INPUT_TYPES, InputType } from '../../services/api/types/steps';

export interface OneOptionData {
  value: string;
  order: number;
  resultTitle?: string;
  resultDescription?: string;
  resultMinPrice?: number;
  imageId: number;
  resultImageId?: number;
}

export interface StepCreateFormState {
  title: string;
  isFinalStep: boolean;
  order: number;
  submitButtonText: string;
  inputType: InputType;
  inputLabel: string;
  options: OneOptionData[];
}

function StepCreatePage() {
  const [stepCreateForm] = Form.useForm<StepCreateFormState>();
  const stepFormValues: StepCreateFormState = {
    title: Form.useWatch('title', stepCreateForm),
    isFinalStep: Form.useWatch('isFinalStep', stepCreateForm),
    order: Form.useWatch('order', stepCreateForm),
    submitButtonText: Form.useWatch('submitButtonText', stepCreateForm),
    options: Form.useWatch('options', stepCreateForm),
    inputLabel: Form.useWatch('inputLabel', stepCreateForm),
    inputType: Form.useWatch('inputType', stepCreateForm),
  };
  const { formId } = useParams();
  const navigate = useNavigate();
  const { token } = useSelector(selectUserState);

  const [responseGetForm, setResponseGetForm] =
    React.useState<ApiFormsGetResponse | null>(null);

  async function loadFormData(tk: string, fid: number) {
    const [response, status] = await apiFormsGet(tk, fid);
    if (status === 200) {
      setResponseGetForm(response);
    } else {
      message.error({ content: 'Error loading form!' });
      navigate('/');
    }
  }

  React.useEffect(() => {
    if (token && formId) {
      loadFormData(token, parseInt(formId, 10));
    }
  }, [token, formId]);

  const createStepCallback = React.useCallback(
    async (values: StepCreateFormState) => {
      if (token && formId) {
        const [, status] = await apiStepsCreate(token, {
          formId: parseInt(formId, 10),
          ...values,
          order: parseInt(values.order as unknown as string, 10),
          ...(values.isFinalStep ? { isFinalStep: values.isFinalStep } : {}),
          options: [
            ...(values.options?.length
              ? values.options.map((opt) => ({
                  ...opt,
                  order: parseInt(opt.order as unknown as string, 10),
                  nextQuestionIndex: null,
                }))
              : []),
          ],
        });
        if (status !== 201) {
          message.error('Error creating step!');
        } else {
          message.success('Successfully created step');
          navigate(`${RouterPaths.FORMS}/${formId}`);
        }
      }
    },
    [token, formId],
  );

  if (!formId) {
    message.error('Missing formId in url!');
    navigate('/');
    return null;
  }

  return (
    <Card bordered={false} title="Step Create">
      {responseGetForm ? (
        <Form form={stepCreateForm} onFinish={createStepCallback}>
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: 'Please input step title!',
                max: 512,
              },
            ]}
          >
            <Input placeholder="Do you need service?" />
          </Form.Item>
          <Form.Item
            label="Order"
            name="order"
            rules={[
              {
                required: true,
                message: 'Please input step order!',
              },
            ]}
            initialValue={0}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Submit Button Text"
            name="submitButtonText"
            rules={[
              {
                required: true,
                message: 'Please input step submit button text!',
                max: 128,
              },
            ]}
            initialValue={'Continue'}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Step Type"
            name="inputType"
            initialValue={InputType.SINGLE_SELECT}
            required={true}
          >
            <Select>
              {ALLOWED_INPUT_TYPES.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Input Label (only for text-input type)"
            name="inputLabel"
          >
            <Input />
          </Form.Item>
          {!stepFormValues.isFinalStep ? (
            <Form.Item label="Data Annotation" name="dataAnnotation">
              <Input maxLength={124} />
            </Form.Item>
          ) : null}
          <Form.Item
            label="Is final step"
            name="isFinalStep"
            valuePropName="checked"
            initialValue={false}
          >
            <Switch />
          </Form.Item>
          {!stepFormValues?.isFinalStep &&
          (stepFormValues.inputType === InputType.SINGLE_SELECT ||
            stepFormValues.inputType === InputType.MULTIPLE_SELECT) ? (
            <Form.List
              name="options"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 2) {
                      // eslint-disable-next-line @typescript-eslint/return-await
                      return Promise.reject(new Error('At least 2 options!'));
                    }
                  },
                },
              ]}
            >
              {(optionFields, { add: optionAdd, remove: optionRemove }) => (
                <>
                  {optionFields.map((optionField) => (
                    <Card style={{ margin: '20px' }} key={optionField.key}>
                      <Form.Item noStyle preserve={false}>
                        <Form.Item
                          label="Value"
                          name={[optionField.name, 'value']}
                          rules={[
                            {
                              required: true,
                              message: 'Please input option value!',
                              max: 512,
                            },
                          ]}
                        >
                          <Input placeholder="Yes" />
                        </Form.Item>
                        <Form.Item
                          label="Order"
                          name={[optionField.name, 'order']}
                          rules={[
                            {
                              required: true,
                              message: 'Please input option order!',
                              max: 512,
                            },
                          ]}
                          initialValue={0}
                        >
                          <Input type="number" />
                        </Form.Item>
                        <ImageInput
                          name={[optionField.name, 'imageId']}
                          label="Option Image"
                          userId={getOwnerOfTheForm(responseGetForm?.data)?.id}
                          nameForUpdate={[
                            'options',
                            optionField.name,
                            'imageId',
                          ]}
                        />
                        <Form.Item
                          label="Result Title"
                          name={[optionField.name, 'resultTitle']}
                          rules={[
                            {
                              required: false,
                              message: 'Max length 512!',
                              max: 512,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Result Description"
                          name={[optionField.name, 'resultDescription']}
                          rules={[
                            {
                              required: false,
                              message: 'Max length 512!',
                              max: 512,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Result Min Price in cents"
                          name={[optionField.name, 'resultMinPrice']}
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input type="number" />
                        </Form.Item>
                        <ImageInput
                          name={[optionField.name, 'resultImageId']}
                          label="Result Image"
                          userId={getOwnerOfTheForm(responseGetForm?.data)?.id}
                          nameForUpdate={[
                            'options',
                            optionField.name,
                            'resultImageId',
                          ]}
                          required={false}
                        />
                        <Button
                          type="dashed"
                          danger
                          onClick={() => {
                            optionRemove(optionField.name);
                          }}
                          block
                          icon={<CloseOutlined />}
                        >
                          Remove option
                        </Button>
                      </Form.Item>
                    </Card>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        optionAdd();
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add option
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          ) : null}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create Step
            </Button>
          </Form.Item>
        </Form>
      ) : (
        'Loading'
      )}
    </Card>
  );
}

export default StepCreatePage;
