import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Table,
  TableProps,
} from 'antd';
import React, { useEffect } from 'react';
import {
  AdminUpdateBillingSubscriptionRequest,
  SubscriptionDto,
} from '../../services/api/types/billing';
import { useForm } from 'antd/es/form/Form';
import { selectUserState } from '../../store/selectors/user';
import { useSelector } from 'react-redux';
import {
  apiListAvailableSubscriptions,
  apiUpdateBillingSubscription,
} from '../../services/api/billing';

interface BillingSubscriptionRow extends SubscriptionDto {
  key: string;
}

// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
interface UpdateBillingSubscriptionsFormValues {
  [key: string]: string | number | null;
}

function BillingSubscriptionEdit() {
  const { token } = useSelector(selectUserState);
  const [form] = useForm<UpdateBillingSubscriptionsFormValues>();
  const [availableSubscriptions, setAvailableSubscriptions] = React.useState<
    SubscriptionDto[] | null
  >(null);
  const [loading, setLoading] = React.useState(false);

  async function loadAvailableSubscriptions() {
    if (token) {
      const [res, status] = await apiListAvailableSubscriptions(token, {});
      if (status === 201 && res.subscriptions) {
        setAvailableSubscriptions(res.subscriptions);
        form.resetFields();
        res.subscriptions.forEach((subscription) => {
          form.setFieldsValue({
            [`${subscription.type}__name`]: subscription.name,
            [`${subscription.type}__description`]: subscription.description,
            [`${subscription.type}__monthlyPriceCent`]:
              subscription.monthlyPriceCent,
            [`${subscription.type}__yearlyPriceCent`]:
              subscription.yearlyPriceCent,
            [`${subscription.type}__lsMonthlyProductId`]:
              subscription.lemonSqueezyData.monthlyProductId,
            [`${subscription.type}__lsMonthlyProductVariantId`]:
              subscription.lemonSqueezyData.monthlyProductVariantId,
            [`${subscription.type}__lsYearlyProductId`]:
              subscription.lemonSqueezyData.yearlyProductId,
            [`${subscription.type}__lsYearlyProductVariantId`]:
              subscription.lemonSqueezyData.yearlyProductVariantId,
          });
        });
      } else {
        message.error('Failed to load available subscriptions');
      }
    }
  }

  async function handleSave(values: UpdateBillingSubscriptionsFormValues) {
    setLoading(true);
    if (token && values && availableSubscriptions?.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const updateBodies: AdminUpdateBillingSubscriptionRequest[] =
        availableSubscriptions.map((sub) => ({
          type: sub.type,
        }));

      Object.entries(values).forEach(([key, value]) => {
        const [type, field] = key.split('__');
        const subscription = updateBodies.find((sub) => sub.type === type);
        const existingSubscription = availableSubscriptions.find(
          (sub) => sub.type === type,
        );
        if (subscription && existingSubscription) {
          let valueToCheck: any = null;
          const newValue = value ?? null;
          if (field === 'lsMonthlyProductId') {
            valueToCheck =
              existingSubscription.lemonSqueezyData?.monthlyProductId;
            if (valueToCheck !== newValue) {
              if (!subscription.lemonSqueezy) {
                subscription.lemonSqueezy = {};
              }
              subscription.lemonSqueezy.monthlyProductId = newValue as
                | string
                | null;
            }
            console.log('MRALE', valueToCheck, newValue, subscription);
          } else if (field === 'lsMonthlyProductVariantId') {
            valueToCheck =
              existingSubscription.lemonSqueezyData?.monthlyProductVariantId;
            if (valueToCheck !== newValue) {
              if (!subscription.lemonSqueezy) {
                subscription.lemonSqueezy = {};
              }
              subscription.lemonSqueezy.monthlyProductVariantId = newValue as
                | string
                | null;
            }
          } else if (field === 'lsYearlyProductId') {
            valueToCheck =
              existingSubscription.lemonSqueezyData?.yearlyProductId;
            if (valueToCheck !== newValue) {
              if (!subscription.lemonSqueezy) {
                subscription.lemonSqueezy = {};
              }
              subscription.lemonSqueezy.yearlyProductId = newValue as
                | string
                | null;
            }
          } else if (field === 'lsYearlyProductVariantId') {
            valueToCheck =
              existingSubscription.lemonSqueezyData?.yearlyProductVariantId;
            if (valueToCheck !== newValue) {
              if (!subscription.lemonSqueezy) {
                subscription.lemonSqueezy = {};
              }
              subscription.lemonSqueezy.yearlyProductVariantId = newValue as
                | string
                | null;
            }
          } else {
            valueToCheck = (existingSubscription as any)[field];
            if (valueToCheck !== newValue) {
              (subscription as any)[field] = newValue;
            }
          }
        }
      });

      const bodiesToUpdate = updateBodies.filter(
        (sub) => Object.keys(sub).length > 1,
      );

      if (!bodiesToUpdate.length) {
        message.info('No changes to save');
        setLoading(false);
        return;
      }

      const promises = bodiesToUpdate.map((body) =>
        apiUpdateBillingSubscription(token, body),
      );
      const res = await Promise.all(promises);
      await loadAvailableSubscriptions();
      if (res.every(([_, status]) => status === 201)) {
        message.success('Successfully saved changes');
      } else {
        message.error('Failed to save changes');
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    loadAvailableSubscriptions();
  }, [token]);

  const columns: TableProps<BillingSubscriptionRow>['columns'] = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <Form.Item name={`${record.type}__name`} initialValue={record.name}>
          <Input />
        </Form.Item>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (_, record) => (
        <Form.Item
          name={`${record.type}__description`}
          initialValue={record.description}
        >
          <Input />
        </Form.Item>
      ),
    },
    {
      title: 'Monthly Price (CENTS)',
      dataIndex: 'monthlyPriceCent',
      key: 'monthlyPriceCent',
      render: (_, record) => (
        <Form.Item
          name={`${record.type}__monthlyPriceCent`}
          initialValue={record.monthlyPriceCent}
        >
          <InputNumber />
        </Form.Item>
      ),
    },
    {
      title: 'Yearly Price (CENTS)',
      dataIndex: 'yearlyPriceCent',
      key: 'yearlyPriceCent',
      render: (_, record) => (
        <Form.Item
          name={`${record.type}__yearlyPriceCent`}
          initialValue={record.yearlyPriceCent}
        >
          <InputNumber />
        </Form.Item>
      ),
    },
    {
      title: 'LemonSqueezy Monthly Product ID',
      dataIndex: 'lemonSqueezyData',
      key: 'lsMonthlyProductId',
      render: (_, record) => (
        <Form.Item
          name={`${record.type}__lsMonthlyProductId`}
          initialValue={record.lemonSqueezyData?.monthlyProductId}
        >
          <Input />
        </Form.Item>
      ),
    },
    {
      title: 'LemonSqueezy Monthly Product Variant ID',
      dataIndex: 'lemonSqueezyData',
      key: 'lsMonthlyProductVariantId',
      render: (_, record) => (
        <Form.Item
          name={`${record.type}__lsMonthlyProductVariantId`}
          initialValue={record.lemonSqueezyData?.monthlyProductVariantId}
        >
          <Input />
        </Form.Item>
      ),
    },
    {
      title: 'LemonSqueezy Yearly Product ID',
      dataIndex: 'lemonSqueezyData',
      key: 'lsYearlyProductId',
      render: (_, record) => (
        <Form.Item
          name={`${record.type}__lsYearlyProductId`}
          initialValue={record.lemonSqueezyData?.yearlyProductId}
        >
          <Input />
        </Form.Item>
      ),
    },
    {
      title: 'LemonSqueezy Yearly Product Variant ID',
      dataIndex: 'lemonSqueezyData',
      key: 'lsYearlyProductVariantId',
      render: (_, record) => (
        <Form.Item
          name={`${record.type}__lsYearlyProductVariantId`}
          initialValue={record.lemonSqueezyData?.yearlyProductVariantId}
        >
          <Input />
        </Form.Item>
      ),
    },
  ];

  const dataSource =
    availableSubscriptions?.map((subscription) => ({
      key: subscription.id,
      ...subscription,
    })) ?? [];

  if (!availableSubscriptions) {
    return null;
  }

  return (
    <Form
      form={form}
      style={{ display: 'flex', flexDirection: 'column', marginBottom: 34 }}
      onFinish={handleSave}
      disabled={loading}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 14,
        }}
      >
        <Button type="primary" htmlType="submit">
          Save Changes
        </Button>
      </div>
      <Table columns={columns} dataSource={dataSource} loading={loading} />
    </Form>
  );
}

export default BillingSubscriptionEdit;
