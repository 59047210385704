/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Switch,
  Table,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import ImageInput from '../../components/ImageInput/imageInput';
import { RouterPaths } from '../../router/paths';
import {
  apiFormsDeletePopup,
  apiFormsGet,
  apiFormsUpdate,
  getOwnerOfTheForm,
} from '../../services/api/forms';
import {
  ApiFormsGetResponse,
  ApiFormsUpdateRequestBody,
  PopupEntity,
} from '../../services/api/types/forms';
import { StepEntity } from '../../services/api/types/steps';
import { selectUserState } from '../../store/selectors/user';
import { MinusCircleOutlined } from '@ant-design/icons';
import { ApiAgencyUsersListResponse } from '../../services/api/types/agency-users';
import { apiAgencyUsersList } from '../../services/api/agency-users';

export interface FormEditFormState {
  name?: string;
  slug?: string;
  brandColor?: string;
  origin?: string;
  widgetTitle?: string;
  widgetButtonText?: string;
  widgetLabel?: string;
  logoImageId?: number;
  developmentMode?: boolean;
  linkable?: boolean;
  requirePhone?: boolean;
  requireAddress?: boolean;
  requireAdditionalNotes?: boolean;
  requireBusinessName?: boolean;
  defaultVisible?: boolean;
  phoneNumber?: string;
  ga4ID?: string | null;
  agencyUserId: number | string;
  calendlyEnabled: boolean;
  calendlyUrl: string | null;
  whatConvertsProfileId: string | null;
  resultScreenRedirect: string | null;
  resultScreenButtonRedirect: string | null;
  facebookPixelId: string | null;
  collectUTM?: boolean;
  googleAdsTagId?: string | null;
  googleAdsDestinationId?: string | null;
  tikTokPixelId?: string | null;
  linkedInPartnerId?: string | null;
  linkedInConversionId?: string | null;
  googleTagManagerId?: string | null;
  googleTagManagerConversionEvent?: string | null;
}

interface DataType extends StepEntity {
  key: string;
}

interface PopupDataType extends PopupEntity {
  key: string;
}

function FormEdit() {
  const { token } = useSelector(selectUserState);
  const { formId } = useParams();
  const navigate = useNavigate();
  const [formEditForm] = Form.useForm<FormEditFormState>();
  const [responseGetForm, setResponseGetForm] =
    React.useState<ApiFormsGetResponse | null>(null);
  const [responseAgencies, setResponseAgencies] =
    React.useState<ApiAgencyUsersListResponse | null>(null);

  async function loadFormData(tk: string, fid: number) {
    const [response, status] = await apiFormsGet(tk, fid);
    if (status === 200) {
      setResponseGetForm(response);
      const agencies = await apiAgencyUsersList(
        {
          offset: 0,
          limit: 999,
        },
        tk,
      );
      if (agencies?.agencyUsers) {
        setResponseAgencies(agencies);
      }
    } else {
      message.error({ content: 'Error loading form!' });
      navigate('/');
    }
  }

  React.useEffect(() => {
    if (token && formId) {
      loadFormData(token, parseInt(formId, 10));
    }
  }, [token, formId]);

  const formEditSubmitCallback = React.useCallback(
    async (values: FormEditFormState) => {
      if (responseGetForm && token) {
        const updateFormRequestBody: ApiFormsUpdateRequestBody = {
          formId: responseGetForm.data.id,
        };
        let shouldUpdate = false;
        const agencyUserParsed =
          !values.agencyUserId || values.agencyUserId === 'no-agency'
            ? null
            : parseInt(values.agencyUserId as any, 10);
        if (values.name !== responseGetForm.data.name) {
          updateFormRequestBody.name = values.name;
          shouldUpdate = true;
        }
        if (values.logoImageId !== responseGetForm.data.logoImage?.id) {
          updateFormRequestBody.logoImageId = values.logoImageId;
          shouldUpdate = true;
        }
        if (values.slug !== responseGetForm.data.slug) {
          updateFormRequestBody.slug = values.slug;
          shouldUpdate = true;
        }
        if (values.brandColor !== responseGetForm.data.brandColor) {
          updateFormRequestBody.brandColor = values.brandColor;
          shouldUpdate = true;
        }
        if (values.origin !== responseGetForm.data.origin) {
          updateFormRequestBody.origin = values.origin;
          shouldUpdate = true;
        }
        if (values.widgetTitle !== responseGetForm.data.widgetTitle) {
          updateFormRequestBody.widgetTitle = values.widgetTitle;
          shouldUpdate = true;
        }
        if (values.widgetLabel !== responseGetForm.data.widgetLabel) {
          updateFormRequestBody.widgetLabel = values.widgetLabel;
          shouldUpdate = true;
        }
        if (values.widgetButtonText !== responseGetForm.data.widgetButtonText) {
          updateFormRequestBody.widgetButtonText = values.widgetButtonText;
          shouldUpdate = true;
        }
        if (
          values.developmentMode !== undefined &&
          values.developmentMode !==
            responseGetForm.data.settings?.developmentMode
        ) {
          updateFormRequestBody.developmentMode = values.developmentMode;
          shouldUpdate = true;
        }
        if (
          values.collectUTM !== undefined &&
          values.collectUTM !== responseGetForm.data.settings?.collectUTM
        ) {
          updateFormRequestBody.collectUTM = values.collectUTM;
          shouldUpdate = true;
        }
        if (
          values.linkable !== undefined &&
          values.linkable !== responseGetForm.data.settings?.linkable
        ) {
          updateFormRequestBody.linkable = values.linkable;
          shouldUpdate = true;
        }
        if (
          values.requirePhone !== undefined &&
          values.requirePhone !== responseGetForm.data.settings?.requirePhone
        ) {
          updateFormRequestBody.requirePhone = values.requirePhone;
          shouldUpdate = true;
        }
        if (
          values.requireBusinessName !== undefined &&
          values.requireBusinessName !==
            responseGetForm.data.settings?.requireBusinessName
        ) {
          updateFormRequestBody.requireBusinessName =
            values.requireBusinessName;
          shouldUpdate = true;
        }
        if (
          values.requireAdditionalNotes !== undefined &&
          values.requireAdditionalNotes !==
            responseGetForm.data.settings?.requireAdditionalNotes
        ) {
          updateFormRequestBody.requireAdditionalNotes =
            values.requireAdditionalNotes;
          shouldUpdate = true;
        }
        if (
          values.requireAddress !== undefined &&
          values.requireAddress !==
            responseGetForm.data.settings?.requireAddress
        ) {
          updateFormRequestBody.requireAddress = values.requireAddress;
          shouldUpdate = true;
        }
        if (
          values.defaultVisible !== undefined &&
          values.defaultVisible !== responseGetForm.data.defaultVisible
        ) {
          updateFormRequestBody.defaultVisible = values.defaultVisible;
          shouldUpdate = true;
        }
        if (
          values.phoneNumber !== undefined &&
          values.phoneNumber !== responseGetForm.data.phoneNumber
        ) {
          updateFormRequestBody.phoneNumber = values.phoneNumber;
          shouldUpdate = true;
        }
        if (
          values.googleTagManagerId !== undefined &&
          values.googleTagManagerId !==
            responseGetForm.data.settings?.googleTagManagerId
        ) {
          updateFormRequestBody.googleTagManagerId = values.googleTagManagerId
            ?.length
            ? values.googleTagManagerId
            : null;
          shouldUpdate = true;
        }
        if (
          values.googleTagManagerConversionEvent !== undefined &&
          values.googleTagManagerConversionEvent !==
            responseGetForm.data.settings?.googleTagManagerConversionEvent
        ) {
          updateFormRequestBody.googleTagManagerConversionEvent = values
            .googleTagManagerConversionEvent?.length
            ? values.googleTagManagerConversionEvent
            : null;
          shouldUpdate = true;
        }
        if (
          values.ga4ID !== undefined &&
          values.ga4ID !== responseGetForm.data.settings?.ga4ID
        ) {
          updateFormRequestBody.ga4ID = values.ga4ID?.length
            ? values.ga4ID
            : null;
          shouldUpdate = true;
        }
        if (
          values.tikTokPixelId !== undefined &&
          values.tikTokPixelId !== responseGetForm.data.settings?.tikTokPixelId
        ) {
          updateFormRequestBody.tikTokPixelId = values.tikTokPixelId?.length
            ? values.tikTokPixelId
            : null;
          shouldUpdate = true;
        }
        if (
          values.linkedInPartnerId !== undefined &&
          values.linkedInPartnerId !==
            responseGetForm.data.settings?.linkedInPartnerId
        ) {
          updateFormRequestBody.linkedInPartnerId = values.linkedInPartnerId
            ?.length
            ? values.linkedInPartnerId
            : null;
          shouldUpdate = true;
        }
        if (
          values.linkedInConversionId !== undefined &&
          values.linkedInConversionId !==
            responseGetForm.data.settings?.linkedInConversionId
        ) {
          updateFormRequestBody.linkedInConversionId = values
            .linkedInConversionId?.length
            ? values.linkedInConversionId
            : null;
          shouldUpdate = true;
        }
        if (
          values.googleAdsTagId !== undefined &&
          values.googleAdsTagId !==
            responseGetForm.data.settings?.googleAdsTagId
        ) {
          updateFormRequestBody.googleAdsTagId = values.googleAdsTagId?.length
            ? values.googleAdsTagId
            : null;
          shouldUpdate = true;
        }
        if (
          values.googleAdsDestinationId !== undefined &&
          values.googleAdsDestinationId !==
            responseGetForm.data.settings?.googleAdsDestinationId
        ) {
          updateFormRequestBody.googleAdsDestinationId = values
            .googleAdsDestinationId?.length
            ? values.googleAdsDestinationId
            : null;
          shouldUpdate = true;
        }
        if (
          values.whatConvertsProfileId !== undefined &&
          values.whatConvertsProfileId !==
            responseGetForm.data.settings?.whatConvertsProfileId
        ) {
          updateFormRequestBody.whatConvertsProfileId = values
            .whatConvertsProfileId?.length
            ? values.whatConvertsProfileId
            : null;
          shouldUpdate = true;
        }
        if (
          values.facebookPixelId !== undefined &&
          values.facebookPixelId !==
            responseGetForm.data.settings?.facebookPixelId
        ) {
          updateFormRequestBody.facebookPixelId = values.facebookPixelId?.length
            ? values.facebookPixelId
            : null;
          shouldUpdate = true;
        }
        if (
          values.calendlyEnabled !== undefined &&
          values.calendlyEnabled !==
            responseGetForm.data.settings?.calendlyEnabled
        ) {
          updateFormRequestBody.calendlyEnabled = values.calendlyEnabled;
          shouldUpdate = true;
        }
        if (
          values.calendlyUrl !== undefined &&
          values.calendlyUrl !== responseGetForm.data.settings?.calendlyUrl
        ) {
          updateFormRequestBody.calendlyUrl = values.calendlyUrl?.length
            ? values.calendlyUrl
            : null;
          shouldUpdate = true;
        }
        if (
          (agencyUserParsed &&
            responseGetForm.data.agencyUser &&
            agencyUserParsed !== responseGetForm.data.agencyUser.id) ||
          (agencyUserParsed === null &&
            responseGetForm.data.agencyUser !== null) ||
          (agencyUserParsed && responseGetForm.data.agencyUser === null)
        ) {
          updateFormRequestBody.agencyUserId = agencyUserParsed;
          shouldUpdate = true;
        }
        if (
          values.resultScreenRedirect !== undefined &&
          values.resultScreenRedirect !==
            responseGetForm.data.settings?.resultScreenRedirect
        ) {
          updateFormRequestBody.resultScreenRedirect = values
            .resultScreenRedirect?.length
            ? values.resultScreenRedirect
            : null;
          shouldUpdate = true;
        }
        if (
          values.resultScreenButtonRedirect !== undefined &&
          values.resultScreenButtonRedirect !==
            responseGetForm.data.settings?.resultScreenButtonRedirect
        ) {
          updateFormRequestBody.resultScreenButtonRedirect = values
            .resultScreenButtonRedirect?.length
            ? values.resultScreenButtonRedirect
            : null;
          shouldUpdate = true;
        }

        if (shouldUpdate) {
          const [response, status] = await apiFormsUpdate(
            token,
            updateFormRequestBody,
          );
          if (status === 200) {
            message.success({ content: 'Form updated successfully!' });
            loadFormData(token, response.data.id);
          } else {
            message.error({ content: 'Failed form update!' });
          }
        }
      }
    },
    [token, responseGetForm],
  );

  const popupColumns = React.useMemo<ColumnsType<PopupDataType>>(
    () => [
      {
        key: 'id',
        title: 'ID',
        fixed: true,
        dataIndex: 'id',
        render: (v) => `${v}`,
      },
      {
        key: 'type',
        title: 'Type',
        dataIndex: 'type',
        render: (v) => `${v}`,
      },
      {
        key: 'title',
        title: 'Title',
        dataIndex: 'title',
        render: (v) => `${v}`,
      },
      {
        key: 'label',
        title: 'Label',
        dataIndex: 'label',
        render: (v) => `${v}`,
      },
      {
        key: 'proceedText',
        title: 'Proceed Text',
        dataIndex: 'proceedText',
        render: (v) => `${v}`,
      },
      {
        key: 'cancelText',
        title: 'Cancel Text',
        dataIndex: 'cancelText',
        render: (v) => `${v}`,
      },
      {
        key: 'triggerTime',
        title: 'Trigger Time (ms)',
        dataIndex: 'triggerTime',
        render: (v) => `${new Intl.NumberFormat().format(v)}`,
      },
      {
        key: 'deleteThisPopup',
        title: 'Delete',
        dataIndex: 'id',
        render: (v) => (
          <MinusCircleOutlined
            className="dynamic-delete-button"
            onClick={async () => {
              if (token && responseGetForm?.data.id) {
                const [response, status] = await apiFormsDeletePopup(
                  { popupId: v },
                  token,
                );
                if (status === 200 && response.data.success) {
                  message.success('Popup successfuly deleted!');
                } else {
                  message.error('Failed to delete popup!');
                }
                loadFormData(token, responseGetForm.data.id);
              }
            }}
            style={{ height: 50, fontSize: 20 }}
          />
        ),
      },
    ],
    [token, responseGetForm],
  );

  const columns = React.useMemo<ColumnsType<DataType>>(
    () => [
      {
        key: 'id',
        title: 'ID',
        dataIndex: 'id',
        fixed: true,
        render: (v) => `${v}`,
      },
      {
        key: 'title',
        title: 'Title',
        fixed: true,
        dataIndex: 'title',
        render: (v) => `${v}`,
      },
      {
        key: 'nextStep',
        title: 'Next Step',
        fixed: true,
        dataIndex: 'nextStep',
        render: (v: StepEntity) =>
          `${
            v
              ? `(${v.id}) ${
                  v.title.length > 20 ? v.title.slice(0, 20) + '...' : v.title
                }`
              : 'No Next Step'
          }`,
      },
      {
        key: 'description',
        title: 'Description',
        dataIndex: 'description',
        render: (v) => `${v}`,
      },
      {
        key: 'isFinalStep',
        title: 'Is Final Step',
        dataIndex: 'isFinalStep',
        render: (v) => (v ? 'Yes' : 'No'),
      },
      {
        key: 'order',
        title: 'Order',
        dataIndex: 'order',
        render: (v) => `${v}`,
      },
      {
        key: 'submitButtonText',
        title: 'Submit Button Text',
        dataIndex: 'submitButtonText',
        render: (v) => `${v}`,
      },
      {
        key: 'stepType',
        title: 'Step Type',
        dataIndex: 'stepType',
        render: (v) => `${v}`,
      },
    ],
    [],
  );

  if (!formId) {
    message.error('Missing formId in url!');
    navigate('/');
    return null;
  }

  return (
    <Card bordered={false} title="Single Form">
      <Typography.Title level={2}>Form Edit</Typography.Title>
      {responseGetForm ? (
        <>
          <div style={{ display: 'flex', width: '100%' }}>
            <Button
              style={{ marginBottom: '45px', marginRight: '25px' }}
              type="primary"
              onClick={() => {
                navigate(
                  `${RouterPaths.TRANSLATIONS}/${responseGetForm?.data.id}`,
                );
              }}
            >
              Edit Translations
            </Button>
            <Button
              style={{ marginBottom: '45px', marginRight: '25px' }}
              type="primary"
              onClick={() => {
                navigate(
                  `${RouterPaths.FORMS}/${responseGetForm?.data.id}/origins`,
                );
              }}
            >
              Edit Origins
            </Button>
            <Button
              style={{ marginBottom: '45px', marginRight: '25px' }}
              type="primary"
              onClick={() => {
                navigate(
                  `${RouterPaths.FORMS}/${responseGetForm?.data.id}/emails`,
                );
              }}
            >
              Edit Notification Emails
            </Button>
            <Button
              style={{ marginBottom: '45px', marginRight: '25px' }}
              type="primary"
              onClick={() => {
                navigate(
                  `${RouterPaths.FORMS}/${responseGetForm?.data.id}/edit-webhooks`,
                );
              }}
            >
              Edit Webhooks
            </Button>
            <Button
              style={{ marginBottom: '45px', marginRight: '25px' }}
              type="primary"
              onClick={() => {
                navigate(
                  `${RouterPaths.FORMS}/${responseGetForm?.data.id}/cnames`,
                );
              }}
            >
              Edit CNAMEs
            </Button>
            <Button
              style={{ marginBottom: '45px', marginRight: '25px' }}
              type="primary"
              onClick={() => {
                navigate(
                  `${RouterPaths.FORMS}/${responseGetForm?.data.id}/seo`,
                );
              }}
            >
              Edit SEO
            </Button>
            <Button
              style={{ marginBottom: '45px', backgroundColor: '#52c41a' }}
              type="primary"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_CLIENT_URL}/${responseGetForm?.data.slug}?tapform-demo-submission-mode=true`,
                  '_blank',
                );
              }}
            >
              Open Demo Form
            </Button>
          </div>
          <Form form={formEditForm} onFinish={formEditSubmitCallback}>
            <Form.Item
              label="Agency Owner"
              name="agencyUserId"
              initialValue={responseGetForm.data.agencyUser?.id || 'no-agency'}
              required={true}
            >
              <Select>
                {(responseAgencies?.agencyUsers
                  ? [...responseAgencies?.agencyUsers, null]
                  : []
                ).map((agency) => (
                  <Select.Option
                    key={agency?.id || 'no-agency'}
                    value={agency?.id || 'no-agency'}
                  >
                    {agency?.email || 'No Agency'}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <ImageInput
              name="logoImageId"
              label="Form Logo"
              userId={getOwnerOfTheForm(responseGetForm.data)?.id}
              defaultImageId={responseGetForm.data.logoImage?.id}
            />
            <Form.Item
              label="Form name"
              name="name"
              rules={[
                { required: true, message: 'Please input form name!', max: 50 },
              ]}
              initialValue={responseGetForm.data.name}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Slug"
              name="slug"
              rules={[
                { required: true, message: 'Please input slug!', max: 50 },
              ]}
              initialValue={responseGetForm.data.slug}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Brand Color"
              name="brandColor"
              rules={[
                {
                  required: true,
                  message: 'Please input brand color!',
                  max: 24,
                },
              ]}
              initialValue={responseGetForm.data.brandColor}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Origin"
              name="origin"
              rules={[
                { required: true, message: 'Please input origin!', max: 512 },
              ]}
              initialValue={responseGetForm.data.origin}
            >
              <Input placeholder="https://example.com/" />
            </Form.Item>
            <Form.Item
              label="Widget Label"
              name="widgetLabel"
              rules={[
                { required: true, message: 'Please widget label!', max: 128 },
              ]}
              initialValue={responseGetForm.data.widgetLabel}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Widget Title"
              name="widgetTitle"
              rules={[
                { required: true, message: 'Please widget title!', max: 32 },
              ]}
              initialValue={responseGetForm.data.widgetTitle}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Widget Button Text"
              name="widgetButtonText"
              rules={[
                { required: true, message: 'Please widget title!', max: 20 },
              ]}
              initialValue={responseGetForm.data.widgetButtonText}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[
                { required: false, message: 'Please phone number!', max: 255 },
              ]}
              initialValue={responseGetForm.data.phoneNumber}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Google Tag Manager ID"
              name="googleTagManagerId"
              rules={[{ required: false, max: 512 }]}
              initialValue={
                responseGetForm.data.settings?.googleTagManagerId || ''
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Google Tag Manager Conversion Event"
              name="googleTagManagerConversionEvent"
              rules={[{ required: false, max: 512 }]}
              initialValue={
                responseGetForm.data.settings
                  ?.googleTagManagerConversionEvent || ''
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Google Analytics 4 Measurement ID"
              name="ga4ID"
              rules={[{ required: false, max: 512 }]}
              initialValue={responseGetForm.data.settings?.ga4ID || ''}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Google Ads Tag ID"
              name="googleAdsTagId"
              rules={[{ required: false, max: 512 }]}
              initialValue={responseGetForm.data.settings?.googleAdsTagId || ''}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Google Ads Destination ID"
              name="googleAdsDestinationId"
              rules={[{ required: false, max: 512 }]}
              initialValue={
                responseGetForm.data.settings?.googleAdsDestinationId || ''
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Facebook Pixel ID"
              name="facebookPixelId"
              rules={[{ required: false, max: 512 }]}
              initialValue={
                responseGetForm.data.settings?.facebookPixelId || ''
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="TikTok Pixel ID"
              name="tikTokPixelId"
              rules={[{ required: false, max: 512 }]}
              initialValue={responseGetForm.data.settings?.tikTokPixelId || ''}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="LinkedIn Partner ID"
              name="linkedInPartnerId"
              rules={[{ required: false, max: 256 }]}
              initialValue={
                responseGetForm.data.settings?.linkedInPartnerId || ''
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="LinkedIn Conversion ID"
              name="linkedInConversionId"
              rules={[{ required: false, max: 256 }]}
              initialValue={
                responseGetForm.data.settings?.linkedInConversionId || ''
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="WhatConverts Profile ID"
              name="whatConvertsProfileId"
              rules={[{ required: false, max: 512 }]}
              initialValue={
                responseGetForm.data.settings?.whatConvertsProfileId || ''
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Calendly URL"
              name="calendlyUrl"
              rules={[{ required: false, max: 2048 }]}
              initialValue={responseGetForm.data.settings?.calendlyUrl || ''}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Redirect URL on Result Screen Button"
              name="resultScreenButtonRedirect"
              rules={[{ required: false, max: 2048 }]}
              initialValue={
                responseGetForm.data.settings?.resultScreenButtonRedirect || ''
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Redirect URL on Result Screen"
              name="resultScreenRedirect"
              rules={[{ required: false, max: 2048 }]}
              initialValue={
                responseGetForm.data.settings?.resultScreenRedirect || ''
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Collect UTM Data"
              name="collectUTM"
              valuePropName="checked"
              initialValue={responseGetForm.data.settings?.collectUTM}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label="Development Mode"
              name="developmentMode"
              valuePropName="checked"
              initialValue={responseGetForm.data.settings?.developmentMode}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label="Require Phone"
              name="requirePhone"
              valuePropName="checked"
              initialValue={responseGetForm.data.settings?.requirePhone}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label="Require Address"
              name="requireAddress"
              valuePropName="checked"
              initialValue={responseGetForm.data.settings?.requireAddress}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label="Require Business Name"
              name="requireBusinessName"
              valuePropName="checked"
              initialValue={responseGetForm.data.settings?.requireBusinessName}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label="Require Additional Notes"
              name="requireAdditionalNotes"
              valuePropName="checked"
              initialValue={
                responseGetForm.data.settings?.requireAdditionalNotes
              }
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label="Form Linkable"
              name="linkable"
              valuePropName="checked"
              initialValue={responseGetForm.data.settings?.linkable}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label="Widget Visible"
              name="defaultVisible"
              valuePropName="checked"
              initialValue={responseGetForm.data.defaultVisible}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label="Enable Calendly"
              name="calendlyEnabled"
              valuePropName="checked"
              initialValue={responseGetForm.data.settings?.calendlyEnabled}
            >
              <Switch />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update Form
              </Button>
            </Form.Item>
          </Form>
        </>
      ) : (
        'Loading'
      )}
      <Typography.Title level={2}>Steps</Typography.Title>
      <Table
        dataSource={
          (responseGetForm
            ? responseGetForm.data.steps
                ?.map((step) => ({
                  ...step,
                  key: step.id,
                }))
                .sort((a, b) => a.order - b.order)
            : []) as unknown as DataType[]
        }
        scroll={{ x: true }}
        loading={responseGetForm === null}
        columns={columns}
        pagination={false}
        onRow={(record) => ({
          onClick: () => {
            navigate(`${RouterPaths.STEPS}/${record.id}`);
          },
        })}
      />
      <Button
        style={{ marginTop: '25px' }}
        type="primary"
        onClick={() => {
          navigate(`${RouterPaths.STEPS}/create/${responseGetForm?.data.id}`);
        }}
      >
        Add Step
      </Button>
      <Typography.Title style={{ marginTop: 50 }} level={2}>
        Popups
      </Typography.Title>
      <Table
        dataSource={
          (responseGetForm
            ? responseGetForm.data.popups?.map((popup) => ({
                ...popup,
                key: popup.id,
              }))
            : []) as unknown as PopupDataType[]
        }
        scroll={{ x: true }}
        loading={responseGetForm === null}
        columns={popupColumns}
        pagination={false}
      />
      <Button
        style={{ marginTop: '25px' }}
        type="primary"
        onClick={() => {
          navigate(
            `${RouterPaths.FORMS}/${responseGetForm?.data.id}/create-popup`,
          );
        }}
      >
        Create Popup
      </Button>
    </Card>
  );
}

export default FormEdit;
