import { Button, Card, Form, Input, Typography, message } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  apiFormsGetFormMetadata,
  apiFormsUpdateFormMetaData,
  getOwnerOfTheForm,
} from '../../services/api/forms';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import {
  ApiFormsGetFormMetaDataResponse,
  ApiFormsUpdateFormMetaDataRequestBody,
} from '../../services/api/types/forms';
import ImageInput from '../../components/ImageInput/imageInput';

interface FormSEOFormValue {
  seoTitle: string;
  seoDescription: string;
  seoImage: number;
}

function FormSEOEdit() {
  const { token } = useSelector(selectUserState);
  const { formId } = useParams();
  const formIdP = formId ? parseInt(formId, 10) : null;
  const navigate = useNavigate();
  const [responseGetFormMetadata, setResponseGetFormMetadata] =
    React.useState<ApiFormsGetFormMetaDataResponse | null>(null);
  const [formEditTranslations] = Form.useForm<FormSEOFormValue>();

  async function loadFormMetaData(tk: string, fid: number) {
    const [response, status] = await apiFormsGetFormMetadata(tk, {
      formId: fid,
    });
    if (status === 200) {
      setResponseGetFormMetadata(response);
    } else {
      message.error({ content: 'Error loading form!' });
      navigate('/');
    }
  }

  React.useEffect(() => {
    if (token && formId) {
      loadFormMetaData(token, parseInt(formId, 10));
    }
  }, [token, formId]);

  const formHandleUpdate = React.useCallback(
    async (values: FormSEOFormValue) => {
      if (
        responseGetFormMetadata &&
        token &&
        values &&
        Object.keys(values).length
      ) {
        const updateFormRequestBody: ApiFormsUpdateFormMetaDataRequestBody = {
          formId: formIdP as number,
        };
        if (values.seoTitle !== undefined) {
          const seoTitleValue = values.seoTitle?.length
            ? values.seoTitle
            : null;
          if (
            seoTitleValue !== responseGetFormMetadata.formMetaData?.seoTitle
          ) {
            updateFormRequestBody.seoTitle = seoTitleValue;
          }
        }
        if (values.seoDescription !== undefined) {
          const seoDescriptionValue = values.seoDescription?.length
            ? values.seoDescription
            : null;
          if (
            seoDescriptionValue !==
            responseGetFormMetadata.formMetaData?.seoDescription
          ) {
            updateFormRequestBody.seoDescription = seoDescriptionValue;
          }
        }
        if (values.seoImage !== undefined) {
          const seoImageValue = values.seoImage ? values.seoImage : null;
          const existingImageValue =
            responseGetFormMetadata.formMetaData?.seoImage === null
              ? null
              : responseGetFormMetadata.formMetaData?.seoImage?.id;
          if (seoImageValue !== existingImageValue) {
            updateFormRequestBody.seoImageId = seoImageValue;
          }
        }
        const [, status] = await apiFormsUpdateFormMetaData(
          token,
          updateFormRequestBody,
        );
        if (status === 201) {
          message.success({ content: 'Form updated successfully!' });
          loadFormMetaData(token, formIdP as number);
        } else {
          message.error({ content: 'Failed form update!' });
        }
      }
    },
    [token, responseGetFormMetadata],
  );

  if (!formId) {
    message.error('Missing formId in url!');
    navigate('/');
    return null;
  }

  return (
    <Card bordered={false} title="Form SEO Edit">
      <Typography.Title level={2}>Form SEO Edit</Typography.Title>
      {responseGetFormMetadata?.formMetaData ? (
        <Form form={formEditTranslations} onFinish={formHandleUpdate}>
          <Form.Item
            label={'SEO Title'}
            name={'seoTitle'}
            initialValue={responseGetFormMetadata?.formMetaData?.seoTitle}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={'SEO Description'}
            name={'seoDescription'}
            initialValue={responseGetFormMetadata?.formMetaData?.seoDescription}
          >
            <Input />
          </Form.Item>
          <ImageInput
            name="seoImage"
            label="Link Image"
            userId={
              getOwnerOfTheForm(responseGetFormMetadata?.formMetaData?.form)?.id
            }
            defaultImageId={responseGetFormMetadata?.formMetaData?.seoImage?.id}
          />
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      ) : null}
    </Card>
  );
}

export default FormSEOEdit;
