import { AgencyUserEntity } from './agency-users';
import { CNAMEDto } from './cname';
import { ImageEntity } from './images';
import { InputType, StepEntity } from './steps';
import { UserEntity } from './users';

export interface FormSettingsEntity {
  linkable: boolean;
  developmentMode: boolean;
  requirePhone: boolean;
  requireAddress: boolean;
  requireAdditionalNotes: boolean;
  requireBusinessName: boolean;
  ga4ID: string | null;
  calendlyUrl: string | null;
  calendlyEnabled: boolean;
  resultScreenRedirect: string | null;
  resultScreenButtonRedirect: string | null;
  facebookPixelId?: string | null;
  collectUTM: boolean;
  whatConvertsProfileId: string | null;
  googleAdsTagId: string | null;
  googleAdsDestinationId: string | null;
  tikTokPixelId: string | null;
  linkedInPartnerId: string | null;
  linkedInConversionId: string | null;
  googleTagManagerId: string | null;
  googleTagManagerConversionEvent: string | null;
}

export interface PopupEntity {
  id: number;
  createdAt: string;
  modifiedAt: string;
  deletedAt: null;
  type: string;
  title: string;
  label: string;
  proceedText: string;
  cancelText: string;
  triggerTime: number;
}

export interface FormEntityTranslations {
  'first-name-input': string;
  'last-name-input': string;
  'email-input': string;
  'address-input': string;
  'city-input': string;
  'zip-input': string;
  'phone-number-input': string;
  'additional-notes-input': string;
  'disclaimer-title': string;
  'disclaimer-text': string;
  'phone-header-cta-label-1': string;
  'phone-header-cta-label-2': string;
}

// Generated by https://quicktype.io

export interface FormNotificationEmail {
  id: number;
  createdAt: string;
  modifiedAt: string;
  deletedAt: null;
  notificationEmail: string;
}

export interface FormOrigin {
  id: number;
  createdAt: string;
  modifiedAt: string;
  deletedAt: null;
  origin: string;
}

export interface FormWebhookEntity {
  id: number;
  createdAt: string;
  modifiedAt: string;
  deletedAt: null;
  url: string;
}

export enum WorkspaceType {
  AGENCY = 'agency',
  PERSONAL = 'personal',
}

export enum WorkspaceUserRole {
  OWNER = 'owner',
  ADMIN = 'admin',
  EDITOR = 'editor',
  MEMBER = 'member',
}

export interface WorkspaceUserEntity {
  id: number;
  aliasId: string;
  createdAt: string;
  modifiedAt: string;
  deletedAt: string | null;
  role: WorkspaceUserRole;
  user: UserEntity;
  workspace: WorkspaceEntity;
}

export enum WorkspaceInvitationStatus {
  PENDING = 'pending',
  SENT = 'sent',
  COMPLETED = 'completed',
}

export interface WorkspaceInvitationEntity {
  id: number;
  aliasId: string;
  createdAt: string;
  modifiedAt: string;
  deletedAt: string | null;
  email: string;
  role: WorkspaceUserRole;
  status: WorkspaceInvitationStatus;
  invitedBy: UserEntity;
  workspace: WorkspaceEntity;
}

export interface WorkspaceEntity {
  id: number;
  aliasId: string;
  createdAt: string;
  modifiedAt: string;
  deletedAt: string | null;
  name: string;
  slug: string;
  type: WorkspaceType;
  parentWorkspace: WorkspaceEntity | null;
  childWorkspaces: WorkspaceEntity[];
  team: WorkspaceUserEntity[];
  invitations: WorkspaceInvitationEntity[];
  forms: FormEntity[];
  cnames: CNAMEDto[];
}

export interface FormEntity {
  id: number;
  createdAt: string;
  modifiedAt: string;
  deletedAt: string | null;
  brandColor: string;
  logoImageId?: number;
  name: string;
  origin: string;
  slug: string;
  widgetButtonText: string;
  widgetTitle: string;
  widgetLabel: string;
  developmentMode: boolean;
  defaultVisible: boolean;
  phoneNumber: string | null;
  translations: FormEntityTranslations;
  logoImage?: ImageEntity;
  steps?: StepEntity[];
  settings?: FormSettingsEntity;
  notificationEmails?: FormNotificationEmail[];
  origins?: FormOrigin[];
  popups?: PopupEntity[];
  agencyUser?: AgencyUserEntity | null;
  webhooks: FormWebhookEntity[] | null;
  workspace: WorkspaceEntity;
}

export interface ApiFormsCreateWizardRequestBody {
  userId: number;
  form: {
    brandColor: string;
    logoImageId: number;
    name: string;
    origin: string;
    slug: string;
    widgetButtonText: string;
    widgetTitle: string;
    linkable: boolean;
    requirePhone: boolean;
    developmentMode: boolean;
    requireAddress: boolean;
    requireAdditionalNotes: boolean;
    createDefaultPopup: boolean;
    resultScreenButtonRedirect: string | null;
    resultScreenRedirect: string | null;
  };
  steps: {
    isFinalStep: boolean;
    order: number;
    submitButtonText: string;
    title: string;
    type: InputType | null;
    inputLabel: string | null;
    nextQuestionIndex: number | null;
    options: {
      imageId: number | null;
      order: number;
      value: string;
      resultDescription?: string;
      resultTitle?: string;
      resultMinPrice?: number;
      resultImageId?: number;
      nextQuestionIndex: number | null;
    }[];
  }[];
}

export interface ApiFormsCreatePopupRequestBody {
  title: string;
  label: string;
  triggerTime: number;
  proceedText: string;
  cancelText: string;
  formId: number;
}

export interface ApiFormsCreatePopupResponse {
  data: {
    success: boolean;
  };
}

export interface ApiFormsDeletePopupRequestBody {
  popupId: number;
}

export interface ApiFormsDeletePopupResponse {
  data: {
    success: boolean;
  };
}

export interface ApiFormsListResponse {
  forms: FormEntity[];
}

export interface ApiFormsGetResponse {
  data: FormEntity;
}

export interface ApiFormsUpdateRequestBody {
  formId: number;
  name?: string;
  slug?: string;
  brandColor?: string;
  origin?: string;
  widgetTitle?: string;
  widgetButtonText?: string;
  widgetLabel?: string;
  logoImageId?: number;
  developmentMode?: boolean;
  requireAdditionalNotes?: boolean;
  requireBusinessName?: boolean;
  linkable?: boolean;
  requireAddress?: boolean;
  requirePhone?: boolean;
  defaultVisible?: boolean;
  phoneNumber?: string;
  translations?: FormEntityTranslations;
  origins?: string[];
  emails?: string[];
  ga4ID?: string | null;
  agencyUserId?: number | null;
  calendlyUrl?: string | null;
  calendlyEnabled?: boolean;
  webhooks?: string[];
  resultScreenButtonRedirect?: string | null;
  resultScreenRedirect?: string | null;
  facebookPixelId?: string | null;
  collectUTM?: boolean;
  whatConvertsProfileId?: string | null;
  googleAdsTagId?: string | null;
  googleAdsDestinationId?: string | null;
  tikTokPixelId?: string | null;
  linkedInPartnerId?: string | null;
  linkedInConversionId?: string | null;
  googleTagManagerId?: string | null;
  googleTagManagerConversionEvent?: string | null;
}

export interface ApiFormsUpdateResponse {
  data: FormEntity;
}

export interface FormMetaDataEntity {
  id: number;
  aliasId: string;
  createdAt: string;
  modifiedAt: string;
  deletedAt: string | null;
  form?: FormEntity;
  seoTitle: string | null;
  seoDescription: string | null;
  seoImage?: ImageEntity | null;
}

export interface ApiFormsGetFormMetaDataRequestBody {
  formId: number;
}

export interface ApiFormsGetFormMetaDataResponse {
  formMetaData: FormMetaDataEntity;
}

export interface ApiFormsUpdateFormMetaDataRequestBody {
  formId: number;
  seoTitle?: string | null;
  seoDescription?: string | null;
  seoImageId?: number | null;
}

export interface ApiFormsUpdateFormMetaDataResponse {
  formMetaData: FormMetaDataEntity;
}
